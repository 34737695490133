<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Upload Bukti Transfer
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()"  >
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Silahkan Upload Bukti Transfer: 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3">
                                                        <Uploader :class="getErrorClass('file_bukti_tf')" :auto="true" :fileLimit="1" :maxFileSize="25000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" style="width:100%" label="Choose files or drop files here" upload-path="fileuploader/upload/file_bukti_tf" v-model="formData.file_bukti_tf"></Uploader>
                                                    </div>
                                                    <small v-if="isFieldValid('file_bukti_tf')" class="p-error">{{ getFieldError('file_bukti_tf') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--[form-content-end]-->
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Upload" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="loading">
            <div style="min-height:200px" class="flex gap-3 justify-content-center align-items-center p-3">
                <div><ProgressSpinner style="width:50px;height:50px" /> </div>
                <div class="text-500">Loading... </div>
            </div>
        </template>
    </main>
</template>
<script setup>
	    import {  computed,  reactive, toRefs, onMounted } from 'vue';
    import { required } from 'src/services/validators';
    import { useApp } from 'src/composables/app.js';
    import { useEditPage } from 'src/composables/editpage.js';
    import { usePageStore } from 'src/store/page';
    const props = defineProps({
        id: [String, Number],
        pageStoreKey: {
            type: String,
            default: 'PEMBAYARAN',
        },
        pageName: {
            type: String,
            default: 'pembayaran',
        },
        routeName: {
            type: String,
            default: 'pembayaranupload_bukti',
        },
        pagePath: {
            type : String,
            default : 'pembayaran/upload_bukti',
        },
        apiPath: {
            type: String,
            default: 'pembayaran/upload_bukti',
        },
        submitButtonLabel: {
            type: String,
            default: "Upload",
        },
        formValidationError: {
            type: String,
            default: "Form is invalid",
        },
        formValidationMsg: {
            type: String,
            default: "Please complete the form",
        },
        msgTitle: {
            type: String,
            default: "Update Record",
        },
        msgBeforeSave: {
            type: String,
            default: "",
        },
        msgAfterSave: {
            type: String,
            default: "Data berhasil diupdate",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type : Boolean,
            default : true,
        },
        isSubPage: {
            type : Boolean,
            default : false,
        },
    });
    const store = usePageStore(props.pageStoreKey);
    const app = useApp();
    const formDefaultValues = Object.assign({
        file_bukti_tf: "", 
    }, props.pageData);
    const formData = reactive({ ...formDefaultValues });
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if(app.isDialogOpen()){
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if(props.redirect) {
            app.navigateTo(`/pembayaran`);
        }
    }
    // form validation rules
    const rules = computed(() => {
        return {
            file_bukti_tf: {  }
        }
    });
    const page = useEditPage({store, props, formData, rules, afterSubmit });
    const {  currentRecord: editRecord } = toRefs(store.state);
    const {  pageReady, saving, loading, } = toRefs(page.state);
    const { apiUrl } = page.computedProps;
    const { load, submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
    onMounted(()=>{
        const pageTitle = "Edit Pembayaran";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
    });
</script>
<style scoped>
</style>
<style scoped>

</style>
